@import "~bootstrap/scss/bootstrap"
@import "./basedata/variables"

*
    font-family: "Open Sans", sans-serif
    color: $gray-400
    font-weight: 400

h6
    font-weight: 300
    color: $gray-700

.login-logo
    max-width: 140px

body
  border: 8px solid $white
  border-radius: 16px
  background-color: $gray-200 !important

.biometry-status
    font-size: 12px
    border: 1px solid $gray-200
    border-radius: 8px
    padding: 6px
    display: inline-block

a.label
    text-decoration: none

    &:hover
        background: $pm-dark

.text-coordinator
    font-size: 12px

.link-coordinator
    text-decoration: none
    text-transform: uppercase
    font-size: 10px

.unread td
    border-bottom-width: 0 !important

.tag-item
    display: inline
    margin: 0 8px 8px 0

.biometric-progress
    width: 200px

.display-table
    margin-bottom: 0 !important
    padding-bottom: 0 !important

.card-access
    .text-numbers
        display: block
        font-size: 110px

.toast-me
    .toast-body
        color: $white

.pikerpop
    position: absolute
    z-index: 2
    min-height: 100vh
    min-width: 100vw
    left: 0
    top: 0

.chrome-picker
    z-index: 3
    position: relative

.pickerColor
    width: 60px
    height: 25px
    border: 1px solid $gray-200
    padding: 2px
    border-radius: 4px

.copyright-botton
  background-color: $pm-primary
  padding: 6px 0
  text-align: center
  font-size: 12px
  color: $white
  margin-top: -30px
  a
    color: $white
    text-decoration: none

.function-list
    text-align: center
    font-size: 10px
    text-transform: uppercase
    color: $gray-700
    padding: 8px 16px

    span
        color: $cyan-600
        display: block

.rounded-md
  border-radius: 16px

.rounded-sm
  border-radius: 8px


.auth-wrapper
  background: url('./assets/images/bg-teste.jpg') left bottom no-repeat
  background-size: cover
  margin-bottom: -16px


.group-list
  list-style: none

  .group-item
    margin-bottom: 16px
    padding-bottom: 16px
    border-bottom: 1px solid $gray-300

.group-detail
  display: flex
  justify-content: space-between

  .srl-only
    color: $gray-500
    font-size: 22px
    margin-left: 20px

.coordinator-list,
.group-action
  padding-left: 0

.group-action
  display: flex
  justify-content: space-evenly

  li
    display: flex
    align-items: center
    margin-left: 16px

    .item-content
      font-size: 12px
      text-transform: uppercase
      color: $gray-800
      margin-left: 16px

    .sidebar-icon
      display: inline-block
      width: 40px
      height: 40px
      padding-top: 6px
      text-align: center
      border-radius: 100%
      overflow: hidden
      background: $gray-300

      &.no-icon
        width: 130px
        border-radius: 20px
        font-size: 13px
        padding-top: 10px
        color: $gray-700

      &.embed-icon
        width: 110px
        border-radius: 20px
        font-size: 10px
        padding-top: 8px
        height: 35px
        color: $gray-700

        svg
          width: 20px
          fill: $gray-800
          color: $gray-800
          vertical-align: top

      svg
        width: 50%
        height: auto
        fill: $gray-800

      &.icon-red
        svg
          color: $red-500
          fill: $red-500

.cordinator-row
  display: flex
  justify-content: flex-start
  align-items: baseline
  margin: 8px 0
  p
    font-size: 17px
    padding-left: 16px
    color: $gray-600


.single-form-creation
  display: flex
  flex-direction: column
  
  h3
    font-size: 18px


  .table-action-search
    margin-bottom: 8px


.group-detail-list
  list-style: none
  margin: 0
  padding-left: 0

  .small-numbers-content
    justify-content: space-between
    padding-right: 10%
    
  .small-numbers .event-ticket-button .event-icon
    width: 80px
    border-radius: 8px

  .chart-title
    width: 35%
    font-size: 18px
.pagination
    display: flex
    justify-content: center


.main-chart
  max-height: 400px

.user-list-title
  margin-left: 34px
  margin-bottom: 16px
  color: $gray-500

.user-entrance-list li
  display: flex
  justify-content: space-between
  border-bottom: 1px solid $white
  padding-bottom: 8px
  margin-bottom: 8px

  .user-avatar
    width: 40px

    img
      width: 100%
      border-radius: 100%
      overflow: hidden

  .user-details
    text-align: left
    width: 75%
    padding-top: 8px


    h5
      font-size: 14px
      margin-bottom: 0

    span
      color: $gray-600
      font-size: 14px

  .user-consessionary
    font-size: 14px
    font-weight: normal
    vertical-align: middle
    margin-left: 8px 
    padding-left: 8px
    border-left: 1px solid $gray-600

  .entrance-icon
    width: 16px
    font-size: 16px
    color: $green-400
    margin-right: 16px
    padding-top: 8px

    svg
      // border-color:  $green-400
      fill: $green-400

.chart-list
  margin-bottom: 40px